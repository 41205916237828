@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Manrope:wght@500&family=Spectral:wght@300&display=swap');

* { 
  margin: 0;
  padding: 0;
}

@media (max-width: 1000px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 12px;
  }

  .hover-image {
    width: 50% !important;
  }
  
  .experience {
    gap: 0.5em !important;
    padding-bottom: 1em !important;
  }

  .role {
    flex-direction: column !important;
    align-items: baseline !important;
  }

  .role-text {
    text-align: left !important;
    margin-left: 0 !important;
  }

  .flex-line {
    display: none !important;
  }

  .screen-warning {
    display: block !important;
  }
}

@media (max-width: 400px) {
  body {
    font-size: 9px;
  }
}

body {
  font-family: 'Manrope', sans-serif;
}

#name {
  color: blue;
}

main {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 20px;
  word-wrap: break-word;
}

::selection {
  color: white;
  background-color: black;
}

h1 {
  font-weight: 700;
  font-size: 4em;
}

.bio {
  font-size: 3em;
  margin-bottom: 1em;
}

a {
  text-decoration: none;
  color: inherit;
  font-weight: 600;
}

a:hover {
  color: blue;
}

.bubble-text {
  background-color: black;
  color: white;
  border-radius: 30px;
  font-family: 'Spectral', serif;
  padding: 0 15px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
}

.role:hover .bubble-text {
  background-color: blue;
  color: black;
}

.role:hover .role-text {
  color: blue;
}

.experience {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  font-size: 2.6em;
}

.role {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.role-text {
  word-wrap: break-word;
  text-align: right;
  margin-left: auto;
}

.flex-line {
  flex-grow: 1;
  border-bottom: 2px solid black;
  margin: 0 10px;
}

.hover-text-block {
  position: relative;
  display: inline;
}

.hover-text {
  position: fixed;
  background: 'white';
  border: '1px solid black';
  padding: '5px';
  font-size: 1em;
  pointer-events: none;
  text-wrap: nowrap;
  color: blue;
  display: inline;
  z-index: 1;
}

.hover-image-block {
  display: inline;
}

.hover-image {
  width: 33%;
  height: auto;
  z-index: 2;
}
