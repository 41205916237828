@import "https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Manrope:wght@500&family=Spectral:wght@300&display=swap";
* {
  margin: 0;
  padding: 0;
}

@media (width <= 1000px) {
  body {
    font-size: 14px;
  }
}

@media (width <= 600px) {
  body {
    font-size: 12px;
  }

  .hover-image {
    width: 50% !important;
  }

  .experience {
    gap: .5em !important;
    padding-bottom: 1em !important;
  }

  .role {
    flex-direction: column !important;
    align-items: baseline !important;
  }

  .role-text {
    text-align: left !important;
    margin-left: 0 !important;
  }

  .flex-line {
    display: none !important;
  }

  .screen-warning {
    display: block !important;
  }
}

@media (width <= 400px) {
  body {
    font-size: 9px;
  }
}

body {
  font-family: Manrope, sans-serif;
}

#name {
  color: #00f;
}

main {
  box-sizing: border-box;
  word-wrap: break-word;
  width: 100vw;
  height: 100vh;
  padding: 20px;
}

::selection {
  color: #fff;
  background-color: #000;
}

h1 {
  font-size: 4em;
  font-weight: 700;
}

.bio {
  margin-bottom: 1em;
  font-size: 3em;
}

a {
  color: inherit;
  font-weight: 600;
  text-decoration: none;
}

a:hover {
  color: #00f;
}

.bubble-text {
  color: #fff;
  background-color: #000;
  border-radius: 30px;
  flex: none;
  padding: 0 15px;
  font-family: Spectral, serif;
}

.role:hover .bubble-text {
  color: #000;
  background-color: #00f;
}

.role:hover .role-text {
  color: #00f;
}

.experience {
  flex-direction: column;
  row-gap: 5px;
  font-size: 2.6em;
  display: flex;
}

.role {
  flex-direction: row;
  align-items: center;
  display: flex;
}

.role-text {
  word-wrap: break-word;
  text-align: right;
  margin-left: auto;
}

.flex-line {
  border-bottom: 2px solid #000;
  flex-grow: 1;
  margin: 0 10px;
}

.hover-text-block {
  display: inline;
  position: relative;
}

.hover-text {
  background: "white";
  border: "1px solid black";
  padding: "5px";
  pointer-events: none;
  text-wrap: nowrap;
  color: #00f;
  z-index: 1;
  font-size: 1em;
  display: inline;
  position: fixed;
}

.hover-image-block {
  display: inline;
}

.hover-image {
  z-index: 2;
  width: 33%;
  height: auto;
}

/*# sourceMappingURL=index.4eb11d66.css.map */
